import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { useTheme, styled } from '@mui/material/styles';

import {
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  Tabs,
  Alert,
  Tab,
} from '@dizzbo/ui';
import { DizzboLogo } from '@dizzbo/ui';

import { useGetProofOfDeliveryUpload } from './queries/useGetProofOfDeliveryUpload';
import { OrderDetail } from './OrderDetail';

function a11yProps(index: number) {
  return {
    id: `pod-upload-tab-${index}`,
    'aria-controls': `pod-upload-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    </Fade>
  );
};

type Props = {};

export const AnonymousProofOfDeliveryUpload: React.FC<Props> = () => {
  const { token } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const { data, isPending, error } = useGetProofOfDeliveryUpload(token);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <Card
      elevation={6}
      variant="filled-primary"
      sx={{
        width: '100%',
        maxWidth: 800,
      }}
    >
      <CardHeader avatar={<DizzboLogo sx={{ width: 74, height: 16 }} dark />} />
      <CardHeader
        title={
          isPending ? (
            <Skeleton variant="rounded" sx={{ height: 24 }} />
          ) : (
            <>Tour {data?.tour?.reference}</>
          )
        }
        variant="medium"
      />

      <CardHeader
        title="Upload CMRs for following shipments:"
        variant="small"
      />
      <CardContent sx={{ paddingX: 0 }}>
        {isPending ? (
          <Skeleton variant="rounded" sx={{ height: 300 }} />
        ) : (
          <>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="CMR upload tabs"
              sx={{
                borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
                paddingLeft: 4,
              }}
            >
              {data.orders.map((order, index) => (
                <Tab
                  key={order.uuid}
                  label={order.reference}
                  {...a11yProps(index + 1)}
                />
              ))}
            </Tabs>
            {data.orders.map((order, index) => (
              <TabPanel key={order.uuid} value={activeTab} index={index}>
                <OrderDetail order={order} token={token} />
              </TabPanel>
            ))}
          </>
        )}
        {error && (
          <Alert severity="error" title="Error" message="Invalid Token." />
        )}
      </CardContent>
    </Card>
  );
};
