import React from 'react';
import { styled } from '@mui/system';
import dayjs from 'dayjs';

import { Box, Typography, Stack, Card } from '@dizzbo/ui';

type Props = {
  delivery: DeliveryType;
};

const DottedLineStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  '&::after': {
    content: "' '",
    position: 'absolute',
    left: 5,
    right: 5,
    top: 12,
    opacity: 0.2,
    border: '1px dashed #081324',
  },
}));

export const Delivery: React.FC<Props> = ({ delivery }) => {
  return (
    <Card variant="outlined" elevation={0} sx={{ paddingX: 6, paddingY: 4 }}>
      <Stack direction="row">
        <Box sx={{ width: '100%', minWidth: 260 }}>
          <Typography
            variant="h5"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {delivery.loadingStop?.country}, {delivery.loadingStop?.postalCode}{' '}
            {delivery.loadingStop?.city}
          </Typography>
          <Typography variant="bodyRegularSmall">
            {delivery.loadingStop?.scheduledAt
              ? dayjs(delivery.loadingStop?.scheduledAt).format('MMM DD, hh:mm')
              : '-'}
          </Typography>
        </Box>

        <DottedLineStyles />

        <Box sx={{ width: '100%', minWidth: 260, textAlign: 'right' }}>
          <Typography
            variant="h5"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {delivery.unloadingStop?.country},
            {delivery.unloadingStop?.postalCode} {delivery.unloadingStop?.city}
          </Typography>
          <Typography variant="bodyRegularSmall">
            {' '}
            {delivery.unloadingStop?.scheduledAt
              ? dayjs(delivery.unloadingStop?.scheduledAt).format(
                  'MMM DD, hh:mm'
                )
              : '-'}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};
