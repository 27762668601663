import React, { FC } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { View } from '@dizzbo/core/views';
import { PageHeader, Box } from '@dizzbo/ui';

export const DashboardView: FC = () => {
  return (
    <View title="Dashboard">
      <PageHeader title="Welcome to Dizzbo TMS" />
      <Box sx={{ overflowY: 'auto', height: 'calc(-64px + 100vh)' }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            paddingTop: 12,
            paddingX: 2,
          }}
        >
          <Grid
            container
            spacing={6}
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingRight: 0,
              paddingBottom: 0,
            }}
          >
            <Grid xs={4}>1</Grid>
            <Grid xs={4}>2</Grid>
            <Grid xs={4}>3</Grid>

            <Grid xs={6}>4</Grid>
            <Grid xs={6}></Grid>
          </Grid>
        </Box>
      </Box>
    </View>
  );
};
