import { SxProps } from '@mui/system';
import React, { forwardRef } from 'react';

import { Avatar } from '../Avatar';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

type Props = {
  username: string;
  avatarSx?: SxProps;
};

export const AvatarListItem = forwardRef<HTMLDivElement, Props>(
  ({ username, avatarSx }) => (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar sx={avatarSx} username={username} />
      <Typography variant="bodyRegular" color="primary">
        {username}
      </Typography>
    </Stack>
  )
);

AvatarListItem.displayName = 'AvatarListItem';
