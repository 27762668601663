import { useQuery } from '@tanstack/react-query';

import { axiosRequest } from '@dizzbo/core/api';
import { QueryKeys } from '@core/config';

export const useGetProofOfDeliveryUpload = (token: TokenType) => {
  const getProofOfDeliveryUploadData = async (
    token: TokenType
  ): Promise<ProofOfDeliveryUploadType> => {
    const res = await axiosRequest.get(`/v2/carriers/pod-upload/${token}/`);
    return res.data;
  };

  return useQuery({
    queryKey: [QueryKeys.ANONYMOUS_POD_UPLOAD, token],
    queryFn: () => getProofOfDeliveryUploadData(token),
  });
};
