import React from 'react';
import { useRoutes } from 'react-router-dom';

import { GuestGuard, AuthGuard } from '@dizzbo/core/routes';
import { OTPLoginView, OTPEnterView } from '@dizzbo/core/views';

import { DashboardLayout } from '@core/layouts';
import { DashboardView } from '@dashboard/views';

import { PoDUploadView } from '../views';

import HeroBackgroundImage from '../../../assets/images/home-hero.jpg';

export const Router = () => {
  return useRoutes([
    {
      path: '/login',
      element: (
        <GuestGuard>
          <OTPLoginView bgImage={HeroBackgroundImage} />
        </GuestGuard>
      ),
    },
    {
      path: '/login/enter-otp',
      element: (
        <GuestGuard>
          <OTPEnterView bgImage={HeroBackgroundImage} />
        </GuestGuard>
      ),
    },
    {
      path: '/pod-upload/:token',
      element: <PoDUploadView />,
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <DashboardView />,
        },
      ],
    },
  ]);
};
