import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { Box } from '@dizzbo/ui';
import { AppBarWrapper } from '@dizzbo/core/components';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  overflowX: 'hidden',
  overflowY: 'hidden',
  display: 'flex',
}));

export const DashboardLayout = () => {
  const navItems = [];
  return (
    <ContainerStyles>
      <AppBarWrapper navItems={navItems}>
        <Outlet />
      </AppBarWrapper>
    </ContainerStyles>
  );
};
