import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axiosRequest } from '@dizzbo/core/api';
import { QueryKeys } from '@core/config';

type PodPayload = {
  order: UUIDType;
  title: string;
  file: string;
};
export const useUpdateProofOfDeliveryUpload = (token: TokenType) => {
  const queryClient = useQueryClient();

  const updateProofOfDeliveryUploadData = async (data: PodPayload) => {
    const res = await axiosRequest.post(
      `/v2/carriers/pod-upload/${token}/`,
      data
    );
    return res.data;
  };

  return useMutation({
    mutationKey: [QueryKeys.ANONYMOUS_POD_UPLOAD, token],
    mutationFn: (values: PodPayload) => updateProofOfDeliveryUploadData(values),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ANONYMOUS_POD_UPLOAD, token],
      });
    },
  });
};
