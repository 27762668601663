import React from 'react';

import { useAuth } from '@dizzbo/core/hooks/useAuth';
import { CheckIcon, MenuItem, PersonIcon, Typography } from '@dizzbo/ui';
import { UUIDType } from 'packages/core/types';

type Props = {};

export const AccountList: React.FC<Props> = () => {
  const { user, switchUserDepartment } = useAuth();
  const handleClick = async (uuid: UUIDType) => {
    switchUserDepartment(uuid);
  };

  return (
    <>
      {user?.departments?.map((department) => (
        <MenuItem
          key={department?.uuid}
          onClick={() => handleClick(department?.uuid)}
          sx={{ minWidth: 374 }}
        >
          <PersonIcon sx={{ width: 16, height: 16, marginRight: 2 }} />
          <Typography variant="buttonRegularSmall">
            {department?.name}
          </Typography>
          {department.uuid === user?.department?.uuid && (
            <CheckIcon sx={{ width: 20, height: 20, ml: 'auto' }} />
          )}
        </MenuItem>
      ))}
      {user?.departments?.length > 1 && (
        <MenuItem
          key={1}
          onClick={() => handleClick(null)}
          sx={{ minWidth: 374 }}
        >
          <PersonIcon sx={{ width: 16, height: 16, marginRight: 2 }} />
          <Typography variant="buttonRegularSmall">All departments</Typography>
          {user?.department === null && (
            <CheckIcon sx={{ width: 20, height: 20, ml: 'auto' }} />
          )}
        </MenuItem>
      )}
    </>
  );
};
