import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { Box } from '@dizzbo/ui';
import { View } from '@dizzbo/core/views';

import HeroBackgroundImage from '../../../assets/images/home-hero.jpg';

import { AnonymousProofOfDeliveryUpload } from '../components/AnonymousProofOfDeliveryUpload';

type Props = {};

export const PoDUploadView: React.FC<Props> = () => {
  return (
    <View title="Upload PoD">
      <Box
        sx={{
          height: '100vh',
          backgroundImage: `url(${HeroBackgroundImage})`,
          backgroundColor: '#17253C',
          backgroundSize: { xs: 'cover', xl: 'cover' },
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
          flexGrow: 1,
          overflowY: 'auto',
          paddingTop: 10,
          paddingX: 6,
        }}
      >
        <Grid container spacing={0} sx={{ height: '100%' }}>
          <Grid
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <AnonymousProofOfDeliveryUpload />
          </Grid>
        </Grid>
      </Box>
    </View>
  );
};
