import { Select as MuiSelect } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import { InputLabelProps } from '@mui/material/InputLabel';
import { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import React, { forwardRef } from 'react';

import { Box } from '../Box';
import { FormControl } from '../FormControl';
import { SwitchIcon } from '../icons';
import { InputLabel } from '../InputLabel';
import { Typography } from '../Typography';

interface BasicSelectPropsOverrides {
  helperText?: string;
}

type BasicSelectProps = MuiSelectProps & BasicSelectPropsOverrides;

const SelectWrapperStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== '',
})<InputLabelProps>(() => {
  return {
    flexDirection: 'row',
  };
});

const InputLabelStyle = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== '',
})<InputLabelProps>(() => {
  return {
    transform: 'none',
    '&.MuiInputLabel-sizeSmall': {
      position: 'relative',
      display: 'inline-flex',
      left: 'auto',
      top: 'auto',
      transform: 'none',
      '&.MuiFormLabel-filled': {
        transform: 'none',
      },
      '&.Mui-focused': {
        transform: 'none !important',
      },
    },
  };
});

const MuiBasicSelectStyle = styled(MuiSelect, {
  shouldForwardProp: (prop) => prop !== '',
})<any>(({ theme }) => {
  return {
    border: 'none',
    color: theme.palette.secondary.dark,
    ...theme.typography.buttonRegularSmall,
    backgroundColor: 'transparent',
    marginLeft: 5,
    '.MuiSelect-select': {
      ...theme.typography.buttonRegularSmall,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: 'transparent',
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&.MuiSelect-standard': {
        paddingRight: 8,
        '&.MuiInputBase-input': {
          paddingRight: 18,
        },
      },
    },
    '.MuiSvgIcon-root': {
      position: 'relative',
      top: 'auto',
      height: 16,
      width: 16,
      color: theme.palette.primary.dark,
    },
  };
});

export const BasicSelect = forwardRef<HTMLDivElement, BasicSelectProps>(
  ({ children, id, label, error, helperText, ...rest }) => {
    const inputLabelId = label && id ? `${id}-label` : undefined;

    return (
      <FormControl fullWidth>
        <SelectWrapperStyle>
          {label && (
            <InputLabelStyle htmlFor={id} id={inputLabelId}>
              <Typography variant="buttonRegularSmall" color="secondary.dark">
                {label}
              </Typography>
            </InputLabelStyle>
          )}
          <MuiBasicSelectStyle
            id={id}
            labelId={inputLabelId}
            input={<InputBase />}
            IconComponent={SwitchIcon}
            variant="standard"
            {...rest}
          >
            {children}
          </MuiBasicSelectStyle>
        </SelectWrapperStyle>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

BasicSelect.displayName = 'BasicSelect';
